/* start custom scss snippet */
.note {
  position: relative;
  margin: 0 0 1rem;
  padding: 15px;
  border: initial;
  border-left: 5px solid #eee;
  background-color: #f9f9f9;
  border-radius: 3px;
}

.note h2,
.note h3,
.note h4,
.note h5,
.note h6 {
  margin-top: 3px;
  margin-bottom: 0;
  padding-top: 0 !important;
  border-bottom: initial;
}

.note p:first-child,
.note ul:first-child,
.note ol:first-child,
.note table:first-child,
.note pre:first-child,
.note blockquote:first-child,
.note img:first-child {
  margin-top: 0 !important;
}

.note p:last-child,
.note ul:last-child,
.note ol:last-child,
.note table:last-child,
.note pre:last-child,
.note blockquote:last-child,
.note img:last-child {
  margin-bottom: 0 !important;
}

.note:not(.no-icon) {
  padding-left: 45px;
}

.note:not(.no-icon)::before {
  position: absolute;
  top: 13px;
  left: 15px;
  font-size: larger;
  font-weight: 600;
  font-family: 'Font Awesome 5 Free';
}

.note.default {
  background-color: #f7f7f7;
  border-left-color: #777;
}

.note.default h2,
.note.default h3,
.note.default h4,
.note.default h5,
.note.default h6 {
  color: #777;
}

.note.default:not(.no-icon)::before {
  content: '\f0a9';
  color: #777;
}

.note.primary {
  background-color: #f5f0fa;
  border-left-color: #6f42c1;
}

.note.primary h2,
.note.primary h3,
.note.primary h4,
.note.primary h5,
.note.primary h6 {
  color: #6f42c1;
}

.note.primary:not(.no-icon)::before {
  content: '\f055';
  color: #6f42c1;
}

.note.info {
  background-color: #eef7fa;
  border-left-color: #428bca;
}

.note.info h2,
.note.info h3,
.note.info h4,
.note.info h5,
.note.info h6 {
  color: #428bca;
}

.note.info:not(.no-icon)::before {
  content: '\f05a';
  color: #428bca;
}

.note.success {
  background-color: #eff8f0;
  border-left-color: #5cb85c;
}

.note.success h2,
.note.success h3,
.note.success h4,
.note.success h5,
.note.success h6 {
  color: #5cb85c;
}

.note.success:not(.no-icon)::before {
  content: '\f058';
  color: #5cb85c;
}

.note.warning {
  background-color: #fdf8ea;
  border-left-color: #f0ad4e;
}

.note.warning h2,
.note.warning h3,
.note.warning h4,
.note.warning h5,
.note.warning h6 {
  color: #f0ad4e;
}

.note.warning:not(.no-icon)::before {
  content: '\f06a';
  color: #f0ad4e;
}

.note.danger {
  background-color: #fcf1f2;
  border-left-color: #d9534f;
}

.note.danger h2,
.note.danger h3,
.note.danger h4,
.note.danger h5,
.note.danger h6 {
  color: #d9534f;
}

.note.danger:not(.no-icon)::before {
  content: '\f056';
  color: #d9534f;
}

kbd {
  display: inline-block;
  color: #666;
  font: bold 9pt arial;
  text-decoration: none;
  text-align: center;
  padding: 2px 5px;
  margin: 0 5px;
  background: #eff0f2;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border-top: 1px solid #f5f5f5;
  -webkit-box-shadow: inset 0 0 20px #e8e8e8, 0 1px 0 #c3c3c3, 0 1px 0 #c9c9c9, 0 1px 2px #333;
  -moz-box-shadow: inset 0 0 20px #e8e8e8, 0 1px 0 #c3c3c3, 0 1px 0 #c9c9c9, 0 1px 2px #333;
  -webkit-box-shadow: inset 0 0 20px #e8e8e8, 0 1px 0 #c3c3c3, 0 1px 0 #c9c9c9, 0 1px 2px #333;
  box-shadow: inset 0 0 20px #e8e8e8, 0 1px 0 #c3c3c3, 0 1px 0 #c9c9c9, 0 1px 2px #333;
  text-shadow: 0 1px 0 #f5f5f5
}

/* end custom scss snippet */
